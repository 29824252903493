@font-face {
    font-family: 'Heebo';
    src: local('Heebo'), url(./assets/fonts/Heebo-VariableFont_wght.ttf) format('truetype');
  }

@font-face {
    font-family: 'IntelOneDisplayRegular';
    src: url('../common/assets/fonts/intelone-display-regular.ttf');
}

@font-face {
    font-family: 'IntelOneDisplayMedium';
    font-style: normal;
    src: url('../common/assets/fonts/intelone-display-medium.ttf'); 
}

@font-face {
    font-family: 'IntelOneDisplayBold';
    font-style: bold;
    src: url('../common/assets/fonts/intelone-display-bold.ttf'); 
}

@font-face {
    font-family: 'IntelOneTextRegular';
    src: url('../common/assets/fonts/intelone-bodytext-font-family-ttf/intelone-text-regular.ttf');
}

@font-face {
    font-family: 'IntelOneTextMedium';
    font-style: normal;
    src: url('../common/assets/fonts/intelone-bodytext-font-family-ttf/intelone-text-medium.ttf'); 
}

@font-face {
    font-family: 'IntelOneTextBold';
    font-style: bold;
    src: url('../common/assets/fonts/intelone-bodytext-font-family-ttf/intelone-text-bold.ttf'); 
}

.form-label-font{
    font-family: "IntelOneTextMedium";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

.selection-term-font{
    font-family: "IntelOneTextMedium";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.bold-instruction-font{
    font-family: "IntelOneTextMedium";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

input[type="checkbox"]{
    color:#0099EC!important;
    background-color: #0099EC!important;
}

html {
    background: linear-gradient(111.51deg, #FFFFFF 0%, #BBD9F1 100%);
    font-family: "IntelOneTextRegular", sans-serif;
}

body {
    margin: 0;
    width: 100%;
    min-width: 1000px;
    min-height: 100vh;
    font-family: 'IntelOneTextRegular', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #222222;
    background-image: url('./assets/background.svg'),linear-gradient(111.51deg, #FFFFFF 0%, #BBD9F1 100%);
    
}

* {
    box-sizing: border-box;
}

strong {
    font-family: 'IntelOneTextRegular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'IntelOneDisplayRegular', sans-serif;
    color: #222222;
}

h1{
    font-family: "IntelOneDisplayMedium" ;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
}

h2{
    font-size:22px
}

h3{
    font-size:20px

}

p {
    margin-bottom: 0.25rem;
    font-size: 1rem;
}

.root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.wrapper{
    width: 80%;
    max-width: 1500px;
    min-width: 600px;
    /* min-height: calc(100vh - 60px); */
    margin: 0 auto;
    position: relative;
    flex: 1 0 auto;
}

.footer {
    /* margin-top: -30px; */
    /* background-color: #F4F4F4; */
    color:#3E3E3E;
    position: relative;
    border-top: 1px solid #BDBDBD;
    width:100%;
    flex-shrink: 0;
}

.footer-img {
    width:10%;
    height:10%;
    margin:1rem;
    background-color: transparent;
}

.footer h1 {
    width:12%;
    height:10%;
    margin: 1.25rem;
    font-family: "IntelOneDisplayBold";
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
}

.footer h1 h6 { 
    font-family: "IntelOneDisplayRegular";
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}

.footer-links{
    width:150px;
    min-width:150px;
    margin:0 auto;
}

.footer .link {
    color: #0054AE!important;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: bold;
}
.footer .link:hover {
    color: #0054AE !important;
    text-decoration: underline!important;
    transition: border 0.4s linear;
}

.footer-content {
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
}

.nav-wrapper {
    width: 100%;
    height:auto;
    background-color: #FEFEFE;
    margin:0 auto;
}

.header-text{
    color: #A0EBFF!important;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}

.header-link-text{
    color: #B8EEFF!important;
    border-bottom: 4px solid!important;
    border-color: transparent!important;
    font-family: "IntelOneTextMedium";
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
}

.header-link-text.active{
    text-decoration: none;
    font-family: "IntelOneTextBold";
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    border-image: linear-gradient(90deg, #6CC4F5 0%, #0099EC 118.88%) 1!important;
    border-bottom: 4px solid;
    padding-bottom: 3px;
    color: #FFFFFF!important;
}

.header-link-text:active{
    text-decoration: none;
    font-family: "IntelOneTextBold";
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    border-image: linear-gradient(90deg, #6CC4F5 0%, #0099EC 118.88%) 1!important;
    border-bottom: 4px solid!important;
    padding-bottom: 3px;
    color: #FFFFFF!important;
}

.header-link-text:hover{
    text-decoration: none;
    font-family: "IntelOneTextBold";
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    border-image: linear-gradient(90deg, #6CC4F5 0%, #0099EC 118.88%) 1!important;
    border-bottom: 4px solid!important;
    padding-bottom: 3px;
    color: #FFFFFF!important;
}

.account-margin-right{
    margin-right: 10px;
}

.navigation {
    width: 100%;
    min-width: 1500px;
    margin:0 auto;
}

.setup-logo{
    margin: 0 auto;
    background-color: #FEFEFE;
    padding : 2rem 4rem;
    clip-path: polygon(0% 0, 100% 0%, 95% 100%, 5% 100%);
}
.setup-nav-container{
    margin: 0 20px;
    background: linear-gradient(90deg, #6CC4F5 0%, #0054AE 47.91%, #001E50 101.09%);
    box-shadow: 0px 0px 12px 0px rgba(0, 84, 174, 0.25);
    height:78px;
    margin-bottom:2rem;
    position:relative;
}

.logo-container {
    background-image: url('./assets/logo-rectangle.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding :1rem 2.85rem;
}

.logo-container img {
    height:100%;
    width: 100%;
}

.nav-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between ;
}

.primary-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between ;
    margin: 0 auto;
    background-color: #1B5AD7;
    padding: 0 2rem;
    clip-path: polygon(2.5% 0, 100% 0, 100% 100%, 1% 100%);

}

.primary-nav a, .authNavigation a {
    text-decoration: none;
    padding: 1rem;
    color:#FFFFFF;
    /* border-bottom: 1px solid #3C3C3C; */
    transition: all 0.2s linear;

}

.primary-nav a {
    margin-left: 0.4rem;
}

.primary-nav a:hover {
    margin-left: 0.4rem;
    color: #1B5AD7;
    /* border-bottom: 1px solid #0056b3; */
}

/* .primary-nav .active {
    border-bottom: 1px solid #1B5AD7;
}

.primary-nav .active:hover {
    border-bottom: 1px solid #fff;
    border-color: #1B5AD7;
} */

.setup-logout{
    position:absolute;
    top:20px;
    left:calc(100vw - 150px);
}

.intelPay-logo{
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 360px;
    background: url("./assets/Subtract.png");
    height: 78px;
}

.header-margin{
    margin-left: 20px;
    padding: 0px!important;
    margin-right: 20px;
}

.nav-button{
    border-radius: inherit;
    border: 1.64px solid #A0EBFF;
    color: #A0EBFF;
    min-width: 95px;
    min-height:35px;
    text-transform:capitalize;
    font-family: "IntelOneTextMedium";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.nav-button:hover{
    color: #FFFFFF;
    border-radius: inherit; 
    text-transform:capitalize;
    min-width: 95px;
    min-height:35px;
    background: linear-gradient(90deg, #6CC4F5 -37.5%, #0054AE 46.29%, #001E50 139.29%);
    box-shadow: 0px 0px 12px 0px rgba(0, 84, 174, 0.25);
}
.header{
    background: linear-gradient(90deg, #6CC4F5 0%, #0054AE 47.91%, #001E50 101.09%);
    box-shadow: 0px 0px 12px 0px rgba(0, 84, 174, 0.25);
}
.wallet-name{
    display:flex;
    justify-content: space-between;
    margin: 0 3.75rem;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    text-transform: capitalize;
    min-height: 22px;
}

.input-text {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #C1C1C1;
    outline: none;
}
.label-margin{
    margin-bottom: 0!important
}

.text-break-word{
    width: 100%;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-break-word:hover
{
    text-overflow:inherit;
    white-space: pre-wrap;
    overflow:visible;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%
}
.transparent-border{
    border: 1px solid transparent;
    padding: 1px 2px;
}
.input-text-error {
    color: #EB001B;
    margin-top:2px;
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    text-align: left;
    text-transform: lowercase; 
}

.input-text-error {
    color: #EB001B;
    margin-top:2px;
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    text-align: left;
    text-transform: lowercase; 
}

.login {
    width: 50%;
    margin: 40px auto;
}

.content {
    flex: 1 0 100%;
    padding: 10px 0 0 0;
}

.username {
    text-decoration: none;
    padding: 10px 10px 10px 10px;
    font-weight: bold;
}

.main-wrapper {
    margin: 2.5rem auto;
    max-width:1000px;
    border: 0.82px solid #DDDDDD;
    box-shadow: 0px 0px 12.718379974365234px 0px #002FA70A;
    background-color: #FFFFFF;
}

.main-wrapper h1 {
    margin-top: 2rem;
}

.dashboard-wrapper {
    width: 100%;
    min-width: 1500px;
    /* min-height: 100vh; */
    margin: 0 auto;
    position: relative;
    flex: 1 0 auto;
}

.dashboard-list-wrapper{
    /* min-height:100vh; */
    margin:0 auto;
    padding: 3rem 2rem;
    
}

.dashboard-card-wrapper{
    min-height:70vh;
    margin:0 auto;
    padding: 3rem 2rem;
}

.header-wrapper{
    margin:0 auto;
    padding-left: 2rem;
}

.breadcrumb p {
    margin-bottom: 1.5rem;
}

/* input styling */


.main-form { 
    margin: 2rem auto;
    max-width: 500px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input {
    display:flex;
    margin: 1rem auto 0.5rem;
    justify-content: space-between;
    align-items: center;
    
}

.checkbox-input .input-margin {
    margin: 0 0.5rem 0.5rem auto;
}

.input-field {
    display: flex;
    flex-direction: column;
}

.input-field-error{
    margin: 0 auto;
}

.required:after {
    content: " *";
    color: #EB001B;
}

.text-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C1C1C1;
    padding: 0.75rem 1.2rem;
    display: block;
    box-shadow: 1px 1px 2px 0px #00000040 inset;
    font-family: "IntelOneTextRegular";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}
.text-input-error-border {
    border: 1px solid #EB001B!important; 
}

.checkbox-error-border{
    border: 1px solid #EB001B!important; 
    appearance: none;
    width: 0.75rem;
    height: 0.75rem;
}
.margin-pass-show-icon{
    margin-right: -21px;
}

.text-input:disabled {
    color: #aaa;
    background-color: #eee;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
}

.text-input:focus {
    border: 1px solid #1B5AD7;
}

.text-input:active {
    border: 1px solid #1B5AD7;
}

.text-input::placeholder {
    color: #aaa
}

.select select{
    padding-right: 2rem;
    background: url(./assets/select.png) no-repeat right #FFFFFF;
    -webkit-appearance: none;
    background-position-x: 98%;
}

.select select::placeholder{
    color: #aaa
}

.select select[readonly] {
    pointer-events:none;
}

.checkbox-input input {
    margin-left: -0.3rem;
}

.button-wrapper {
    margin-top: 3rem;
    text-align: center;
    position:relative;
}

.bottomright {
    bottom:.5rem;
    right:1rem;
}

.button {
    background-color:#1B5AD7;
    color: #ffff;
    min-width:150px;
    min-height: 45px;
    background: linear-gradient(90deg, #6CC4F5 -37.5%, #0054AE 46.29%, #001E50 139.29%);
    box-shadow: 0px 0px 12px 0px #0054AE40;
    padding:0.5rem 1.5rem;
    align-items:center;
    font-size:18px;
    text-transform:capitalize;
    border-radius: inherit;
}

.button:disabled {
    background-color:#1B5AD7;
    border: 2px solid #0054AE;
    opacity: 0.3;
    color: #ffff;
    min-width:150px;
    min-height: 45px;
    align-items:center;
    text-transform:capitalize;
    border-radius: inherit;
}

.button-outline {
    color: #0054AE;
    font-size:18px;
    text-transform:capitalize;
    font-weight:600;
    border: 2px solid #0054AE;
    min-width: 170px;
    min-height: 45px;
    width: fit-content;
    border-radius: inherit;
    background: none;
}
button.btn-outline-light{
    /* background: rgba(160, 235, 255, 1); */
    border: 1.64px solid #A0EBFF;
    border-radius: inherit;
}
.btn-outline-primary:hover {
    color: #ffff;
    border-radius: inherit;
    font-size:18px;
    text-transform:capitalize;
    font-weight:600;
    min-width: 170px;
    min-height: 45px;
    background: linear-gradient(90deg, #6CC4F5 -37.5%, #0054AE 46.29%, #001E50 139.29%);
    box-shadow: 0px 0px 12px 0px #0054AE40;
}

.button-outline:disabled {
    color: grey;
    border: 2px grey  solid;
    background-color:#ffff;
    border-radius: inherit;
    font-size:18px;
    text-transform:capitalize;
    font-weight:600;
    min-width: 170px;
    min-height: 45px;
}

.button-tertiary-outline {
    color: #1B5AD7;
    border: 2px #1B5AD7 solid;
    background-color:#ffff;
    min-width:fit-content;
    border-radius: inherit;
    font-size:12px;
    text-transform:capitalize;
    font-weight:400;
    text-align: center;
    padding: 0.25rem 1rem;
}

.button-tertiary-outline:hover {
    color: #ffff;
    background-color:#1B5AD7;
    border-color:#1B5AD7;
    min-width:fit-content;
    font-size:12px;
    text-transform:capitalize;
    font-weight:400;
    text-align: center;
    padding: 0.25rem 1rem;
}

.button-tertiary-outline:disabled {
    color: grey;
    border: 2px grey solid;
    background-color:#ffff;
    min-width:fit-content;
    font-size:12px;
    text-transform:capitalize;
    font-weight:400;
    text-align: center;
    padding: 0.25rem 1rem;
}

.link {
    color:  #0054AE;
    cursor: pointer;
    font-family: "IntelOneTextMedium";
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
}

.radio-label {
    margin-top:1rem;
    padding: 1.5rem;
    width: 100%;
    text-align: center;
    background-color: #fff;
    border: 1px #4E4E4E solid;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    border-radius: 4px;
}

.radio-label p {
    margin-bottom: 0;
}

.radio-label input {
    display: none;
}

.disabled-radio {
    color: #888;
    background-color: #4E4E4E;
}

.active-radio {
    /* border: 2px #1B5AD7 solid; */
    border-color: transparent; /* remove the border's colour */
    box-shadow: 0 0 0 2px #1B5AD7;
}

.valid { 
    /* height: 0.9rem; */
    margin: 0.5rem auto;
}   

/* Page styling */

.page-title {
    text-align: center;
}

.loading {
    color: #aaa;
    padding: 3rem 0; 
    text-align: center;
}

.loaded { 
    padding: 4rem 0;
    text-align: center;
}


.bottom-bar {
    position: fixed;
    bottom: 0;
    background-color: #FEFEFE;
    width: 100%;
    left: 0;
    /* box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25); */
    border-top: 1px #4E4E4E solid;
    z-index: 999;
}

.bottom-bar > div {
    width: 80%;
    max-width: 900px;
    min-width: 600px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
}

.bottom-bar-spacer {
    height: 2rem;
}

.account-header {
    margin-top: 1.5rem;
}

.page-header {
    margin-top: 0.5rem;
    display: flex;
    align-items: baseline;
}

.page-header div {
    margin-left: auto;
}

/* device card wrapper */

.list-section{
    background-color: #FFFFFF;
    border:1px solid #DDDDDD;
    border-radius: 10px;
}

.card-wrapper {
    /* box-sizing: border-box; */
    /* -webkit-user-select: none;  Chrome all / Safari all */
    /* -moz-user-select: none;     Firefox all */
    /* -ms-user-select: none;      IE 10+ */
    /* margin: 1rem; */
}

.card {
    background-color: #FFFFFF;
    padding-bottom: 3rem;
    border: 0.82px solid #DDDDDD;
    box-shadow: 0px 0px 12.718379974365234px 0px #002FA70A;
}

.card-wrapper p {
    margin: 0;
    line-height: 2.1;
}

.card-toggle {
    padding: 0.5rem 0;
    margin: 0 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size:24px ;
}

.card-toggle svg {
    margin-left: auto;
    height: 1.4rem;
}

.card-toggle.expanded {
    border-bottom: 1px #4E4E4E solid;
}

.card-toggle.expanded svg {
    margin-left: auto;
    width: 1.4rem;
}

.card-content {
    padding: 0.6rem 0 1.1rem;
    margin: 0 2rem;
    margin-top: -1px;
}

.card-info {
    display: flex;
    margin: 0.3rem 0;
    align-items: baseline;
}

.card-info > div {
    margin-left: auto;
}


.card-delete {
    color: #F44343;
    cursor: pointer;
}

.card-delete:hover {
    text-decoration: underline;
    transition: border 0.4s linear;
    color: #F44343;
}




.page-link {
    color: #1B5AD7!important;
    text-decoration: none;
    cursor: pointer;
    border:none
}

.page-link:hover {
    color: #1B5AD7!important;
    text-decoration: underline!important;
    background-color: #FEFEFE;
    /* transition: border 0.4s linear; */
}

.page-link-disabled{
    color: #E1E1E1!important;
    text-decoration: none;
    cursor: pointer;
    border:none
}

.error{
    color: #F44343;
    line-height: 0.9rem;
    margin: 0.5rem ;
    font-size: 14px;
    /* padding-left: 0.5rem; */
}

hr {
    margin: 0.5rem 0 0.5rem;
    border-top: 1px solid #4E4E4E;
} 

.divider {
    border-bottom: #DDDDDD solid 1px;
}

.divider-top {
    border-top: #DDDDDD solid 1px;
}


.finger-scan-gif{
    width:70px;
    height:100;
}

.icons{
 border:none;
}

.form-icon{
    width:49.19px;
    height:58.888px;
}


.terms{
    margin:2rem 0;
    overflow-y:auto;
    overflow-x:visible;
    max-height:300px;
    width:100%
}

.cards-logo{
    width:20%;
    height:20%;
    /* margin-right: 0.5rem; */
    
}
.visa-logo{
    width:20%;
    height:20%;  
    /* margin-right: 0.5rem; */

}

.amex-logo{
    width:15%;
    height:20%;
    padding: 0.75rem;  
    /* margin-right: 0.5rem; */

}

.cards-logo-disabled{
    opacity: 0.2;
}

@keyframes spinning {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

.loading-logo {
    animation-name: spinning;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 150px;
    margin: 100px auto;
  }
.loading-logo-in-card img.loading-logo{
    margin:0!important;
    height: 100px!important;

}

  /* card list styling */

.list-card{
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 15.4972px rgba(0, 0, 0, 0.1));
    margin:0.5rem 0;
    padding:1rem;
    padding-right:2rem;
    cursor: pointer;
}
.list-card:hover{
    background: #FFFFFF;
}
.active-default-card{
    background: #FFFFFF;
    border: 0.82px solid #DDDDDD;
    box-sizing: border-box;
    box-shadow: 0px 0px 12.718379974365234px 0px #002FA70A;
    margin: 0 auto;
    padding:1.5rem;
    cursor: pointer;
 }

 .inactive-active-default-card{
    background: none;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 auto;
    cursor: pointer;
 }

.active-list-card{
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    box-shadow: 0px 0px 15.4972px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-width:340px;
    max-width:340px;
    padding:1.5rem;
    cursor: pointer;
 }

 .active-list-top-padding{
    padding-top:8.5rem;
 }

 .active-list-bottom-padding{
    padding-bottom:2.5rem;
 }

 .inactive-list-padding{
    padding-top:7.5rem;

 }

 .inactive-list-card{
    background:none;
    border: none;
    box-sizing: border-box;
    margin:0 auto;
    cursor: pointer;
 }


.active-card-header {
    padding: 3rem 0.5rem 0rem 0.5rem;
    margin: 0 3rem;
}

.active-card-flex-header {
    padding: 3rem 0.5rem;
    margin: 0 3rem;
    display: flex;
    justify-content: space-between;
}

.card-link-bar{
    display: flex;
    justify-content:flex-start;
}

.card-link-bar div {
    padding: 20px 0;
    color: #0054AE;

    /* transition: all 0.2s linear; */
    /* font */
    font-family: "IntelOneTextMedium";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: right;

}

.card-link-bar div:hover {
    color: #222222;
    cursor: pointer;
}

.card-link-bar .active{
    color: #222222;
    border-bottom: 4px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #FFF, #FFF), linear-gradient(90deg, #6CC4F5 0%, #0099EC 118.88%);
    cursor: pointer;
}

.card-link-bar .active:hover {
    border-color: #1B5AD7;
}

.active-card-content{
    padding: 0 0.5rem;
    margin: 0 3rem;
}

.active-card-details{
    display:flex;
    justify-content: space-between;
    padding: 2rem 0;
}

.active-card-details p{
    line-height:1.5;
}

.card-icons{
    cursor: pointer;
}

.delete-icon{
    color:#0099EC;
    cursor: pointer;
}

.delete-icon:hover{
    color:#F44343;
    cursor: pointer;
}

.default-card-art{
    border: 1px solid #0099EC;
    border-radius: 10px;
    width:100%;
    max-width: 100%;
    height:161px;
    max-height:161px;
    margin: 0 auto;
}


.cards-container {
    position: relative;
    color: transparent;
}

.card-stack{
    /* border: 1px solid #000000; */
    border:1px solid #0099EC;
    border-radius: 10px;
    width:95%;
    max-width:95%;
    height:161px;
    margin-top:-120px;
    margin-left:0.3rem;
    cursor: pointer;
}

.card-stack:hover{
    height: 161px;
    margin-left:2rem;
    margin-right:-2rem;
}

.top-card{
    background-color: #212121;
    /* border: 1px solid #000000; */
    display:block;
    border-radius: 10px;
    width:105%;
    max-width:296px;
    height:168px;
    max-height:168px;
    z-index:3;
    margin-left:-7px;
    position:absolute;
    /* padding-bottom:4rem; */
}

.top-card:hover{
    height:168px;
    margin-left:-7px;
    margin-right:2rem;
}

.top-card-text{
    color:#FFFFFF;
    position:absolute;
    z-index:4;
    top: 0.25rem;
    left:1rem;
}

.default-card-art-text{
    color:#FFFFFF;
    position:absolute;
    bottom:-10px;
    left:1.5rem;
   text-transform: capitalize; 
}

.card-art-text{
    color:#FFFFFF;
    position:absolute;
    bottom:7px;
    left:1.75rem;
    text-transform: capitalize;
}

.other-card-label{
    margin-top:-100px;
    position:absolute;
    min-width: 200px;
    text-align: left;
    text-transform: capitalize;
}

.card-status-label{
    margin-top:-100px;
    margin-right:-5%;
    position:absolute;
    min-width: 200px;
    text-align: right;
    text-transform: capitalize;
    font-family: "IntelOneTextMedium";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
}

.dashboard-list-wrapper .card-section-label{
    margin-right: auto;
    padding-left:0.5rem;
    font-family: "IntelOneTextMedium";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

/* finger list card styling */

.finger-scan-thumbnail{
    width: 42px;;
    height: 59px;;
}


/* account overlay styles */

#account{
    min-width:500px
}

.change-pwd-label{
    font-size: 14px;
}

.account-icon{
    color:#A0EBFF;
    padding:0;
    background-color: transparent;
    border: 1.64px solid #A0EBFF;
    border-radius: 100px; 
}

.account-icon:hover{
    color:#FFFFFF;
    padding:0;
    border:1px solid #FFFFFF ;
    border-radius: 100px; 
}

.account-icon:focus{
    color:#A0EBFF;
    padding:0;
    border:1px solid #FFFFFF ;
    border-radius: 100px; 
}

.account-icon:active{
    color:#FFFFFF;
    padding:0;
    border:1px solid #FFFFFF ;
    border-radius: 100px; 
}

.account-icon-clicked{
    background-color: #A0EBFF;
    color:#0054AE;
    padding:0;
    border:1px solid #A0EBFF ;
    border-radius: 100px;
}

.edit-icon{
    color:#0099EC;
    cursor: pointer;
}

.edit-icon:hover{
    color:#A0EBFF;
    cursor: pointer;
}

.account-edit-icon{
    color:#A0EBFF;
    border:none;
    padding:0;
    background-color: transparent;
}

.account-edit-icon:hover{
    background-color: transparent;
    color:#FFFFFF;
    padding:0;
}

.account-edit-icon:focus{
    background-color: transparent;
    color:#A0EBFF;
    padding:0;
    border:1px solid #FFFFFF ;
}

.account-edit-icon:active{
    background-color: transparent;
    color:#A0EBFF;
    padding:0;
    border:1px solid #FFFFFF ;
}

.account-edit-icon-clicked{
    background-color: transparent;
    color:#A0EBFF;
    padding:0;
    border:1px solid #FFFFFF ;
}

.account-dismiss :hover{
    cursor: pointer;
}

  /* transcation history table */

  .table td, .table th{
      vertical-align: middle;
  }

  tbody th{
      font-weight:normal   
  }

  .table-hover tbody tr:hover{
      background-color:#DAE7FF ;
  }

  .toast {
    min-width: fit-content;
    max-width: 80%;
  }

  .banner-header{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    min-height: 50px;
    min-width: 100%;
    font-size:18px;
    padding:0.75rem 1.25rem;
  }

  .toast-div{
      position:absolute;
      display:flex;
      justify-content:right;
      min-width: 100%;
      width: 100%;
      z-index:999;
      top:-3%;
      left:-2%
  }

  .pass-show{
    position:relative;
    display:inline-block;
    width:100%
  }
  
  .pass-show-icon{
    width: 21px;
    height: 14px;
    position: relative;
    top: 12px;
    right: 30px;
    cursor: pointer;
  }

 .watermark{
      background-image: url("./assets/Sample\ Data\ Only.png");
      background-repeat: repeat-y;
      background-position: center;
  }

  .demo-store-logo{
      width: 40%;
  }

.pcwallet-promo{
    width:70%;
    border-radius: 10px;
}

.promo-watermark{
    margin-top:10rem;
    margin-left:7rem;
    width:30%;
    position:absolute;
    opacity:0.5;
}

.promo-button{
    text-align:right;
    margin-right:6rem;
    padding:2.5rem;
}

.cards-promo {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    width:350px;
    height:95%;
}

.cards-promo-watermark{
    margin-top:1.5rem;
    margin-left:1rem;
    position:absolute;
    opacity:0.6;
}

.home-wrapper {
    width: 100%;
    min-width: 1500px;
    margin: 0 auto;
    position: relative;
    /* flex: 1 0 auto; */
    top:-30px;
}

.home-left-panel{
    min-height:100vh;
    border-right: 1px solid #DDDDDD;
    background-color: #F6F6F6 ;
    margin:0 auto;
    padding: 3rem 2rem;    
}

.vision-text{
    margin-top :5rem;
    font-size: 28px;
    line-height: 1.3;
    color:#1a56cc;
    font-weight: bold;
    text-align: left;
}

.locked-status{
    color: #fd6767;
    padding: 0.5rem ;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid #fd6767;
    border-radius: 5px;
}

.green-label{
    color: #155724;
    padding: 0.25rem ;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid #155724;
    border-radius: 5px;
}

.card-thumbnail{
    width: 95px;
    height: 60px;
    max-height: 60px;
    border-radius: 5px;
    margin-left: 5px;
}

.pcwallet-logo-h1{
    font-family: "IntelOneDisplayBold";
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
}

.pcwallet-logo-h6{
    font-family: "IntelOneDisplayRegular";
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}

.pcwallet-logo-h1-dashboard{
    font-family: "IntelOneDisplayBold";
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
}

.accordion {
    --accordion-color: #4E4E4E;
    --accordion-bg: #fff;
    --accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --accordion-border-color: #dee2e6;
    --accordion-border-width: 1px;
    --accordion-border-radius: 0.375rem;
    --accordion-inner-border-radius: calc(0.375rem - 1px);
    --accordion-btn-padding-x: 1.25rem;
    --accordion-btn-padding-y: 1rem;
    --accordion-btn-color: #4E4E4E;
    --accordion-btn-bg: #fff;
    --accordion-btn-icon: url(./assets/select.png);
    --accordion-btn-icon-width: 1.25rem;
    --accordion-btn-icon-transform: rotate(-180deg);
    --accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --accordion-btn-active-icon: url(./assets/select.png);
    --accordion-btn-focus-border-color: #86b7fe;
    --accordion-btn-focus-box-shadow: 0 0 0 0.25remrgba(13,110,253,0.25);
    --accordion-body-padding-x: 1.25rem;
    --accordion-body-padding-y: 1rem;
    --accordion-active-color: #0c63e4;
    --accordion-active-bg: #e7f1ff;
}

.accordion-item {
    color: var(--accordion-color);
    background-color: var(--accordion-bg);
    border: var(--accordion-border-width) solid var(--accordion-border-color);
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
    border-top: 0;
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--accordion-btn-padding-y) var(--accordion-btn-padding-x);
    font-size: 1.25rem;
    color: var(--accordion-btn-color);
    text-align: left;
    background-color: var(--accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--accordion-transition);
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1 0 var(--accordion-border-color);
}

.accordion-button:after {
    flex-shrink: 0;
    width: var(--accordion-btn-icon-width);
    height: var(--accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--accordion-btn-icon-width);
    transition: var(--accordion-btn-icon-transition);
}

.accordion-button:not(.collapsed):after {
    background-image: var(--accordion-btn-active-icon);
    -webkit-transform: var(--accordion-btn-icon-transform);
    transform: var(--accordion-btn-icon-transform);
}

.accordion-body {
    padding: var(--accordion-body-padding-y) var(--accordion-body-padding-x);
}

.error-message {
    max-width:1000px;
    margin: 2.5rem auto;
}

.progress-indicator{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2.5rem auto;
    max-width:1000px;
    padding-right: 2rem;
}

.progress-indicator-step{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    min-width: 200px;
    max-width:200px;
}

.progress-indicator-step-label{
    font-weight: bold;
    font-size: 20px;
    color:#3C3C3C;
    width: 150px;
    min-width: 150px;
    max-width:150px;
    text-align: center;

}

.progress-indicator-selected-color{
    color: #1B5AD7;
    text-align: center;
}

.progress-indicator-hr{
    width:200%;
    color:#3C3C3C;
}

.form-required-instruction-label{
    font-size: 12px;
    font-weight: bold;
    margin:0;
    display: flex;
    justify-content:flex-end;    
}